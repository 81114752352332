/* custom.css */
.p-autocomplete-items .p-highlight {
  background-color: var(
    --primary-color
  ) !important; /* Custom background color */
  color: #fff !important; /* Custom text color */
}

.highlighted-row {
  /* background-color: #ea9999 !important; */
  color: rgb(170, 40, 0) !important;
}
